import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.dev';
import { APIResponseCodeEnum } from '../models/enums/api-response-code.enum';
import { getMessage } from '../models/enums/message-code.enum';
import { RequestParam } from '../models/request-param';
import { LoadingService } from './loading.service';
import { SnackbarService } from './snackbar.service';
import { IHttpErrorReponse } from '../models/http-error-response';
@Injectable({
  providedIn: 'root'
})
  
export class RequestService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private snackbarService: SnackbarService
  ) {
  }
  getUrl(path: string) {
    let arr = path.split('/').filter(v => v);
    arr.unshift(environment.api_url)
    return arr.join('/')
  }
  get<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data, true);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    return this.http.get<T>(url, { params: request.data }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  getBlob(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data, true);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    return this.http.get(url, { params: request.data, responseType: 'blob' }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  getJSON<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data, true);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<T>(url, { params: request.data, headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  post<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    request.data = this.toFormData(request.data);
    return this.http.post<T>(url, request.data, { headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  postJSON<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post<T>(url, request.data, { headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  postFile<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');
    request.data = this.toFormData(request.data);
    return this.http.post<T>(url, request.data, { headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  patchFile<T>(path: string, request: RequestParam): Observable<T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');
    request.data = this.toFormData(request.data);
    return this.http.patch<T>(url, request.data, { headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  postFileProgress<T>(path: string, request: RequestParam): Observable<number | T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');
    request.data = this.toFormData(request.data);
    return this.http.post<T>(url, request.data, { headers, reportProgress: true, responseType: 'json', observe: "events" }).pipe(
      filter(res => res.type == HttpEventType.UploadProgress || res.type == HttpEventType.Response),
      map(res => {
        if (res.type == HttpEventType.UploadProgress) {
          return Math.round(res.loaded / (res.total || 0) * 100);
        } else {
          return (res as HttpResponse<T>).body || {} as T
        }
      }),
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  patchFileProgress<T>(path: string, request: RequestParam): Observable<number | T> {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');
    request.data = this.toFormData(request.data);
    return this.http.patch<T>(url, request.data, { headers, reportProgress: true, responseType: 'json', observe: "events" }).pipe(
      filter(res => res.type == HttpEventType.UploadProgress || res.type == HttpEventType.Response),
      map(res => {
        if (res.type == HttpEventType.UploadProgress) {
          return Math.round(res.loaded / (res.total || 0) * 100);
        } else {
          return (res as HttpResponse<T>).body || {} as T
        }
      }),
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  patchJSON<T>(path: string, request: RequestParam) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.patch<T>(url, request.data, { headers }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  deleteJSON<T>(path: string, request: RequestParam = {}) {
    const url = this.getUrl(path);
    this.clean(request.data);
    if (request.is_loading) {
      this.loadingService.setLoading(true);
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete<T>(url, { headers, params: request.data }).pipe(
      catchError((err) => this.handleHttpError(err, request.is_alert_error)),
      finalize(() => this.finalizeRequest(request.is_loading))
    );
  }

  private clean(obj: any, isCleanQuery = false) {
    for (const propName in obj) {
      if (obj[propName] === undefined || (isCleanQuery && obj[propName] === null)) {
        delete obj[propName];
      } else if (obj[propName] instanceof Date) {
        (obj[propName] as Date).setMilliseconds(0);
        obj[propName] = (obj[propName] as Date).toISOString();
      } else if (typeof obj[propName] == 'object' && !(obj[propName] instanceof File)) {
        this.clean(obj[propName])
      }
    }
  }


  private handleHttpError(error: HttpErrorResponse, is_alert_error: boolean = true) {
    let errorObject: IHttpErrorReponse = error.error;
    let erorrMessage = errorObject.code != undefined ? `${errorObject.code}, ` : ' ';
    erorrMessage += errorObject.message;
    if (is_alert_error) {
      this.snackbarService.show({message: erorrMessage, isError: true });
    }
    return throwError(() => error.error)
  }

  private finalizeRequest(is_loading?: boolean) {
    if (is_loading) {
      this.loadingService.setLoading(false);
    }
  }

  private toFormData(formValue: any) {
    const formData = new FormData();
    //to append files to last of form data
    const fileKeys = []
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (typeof value?.name == 'string') {
        fileKeys.push(key);
        continue;
      }
      formData.append(key, value);
    }
    for (const key of fileKeys) {
      formData.append(key, formValue[key]);
    }
    return formData;
  }

}